import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
}

html, body,  #root>.App, App>div {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%; 
  overflow-x: hidden;
  overflow-y: auto;
}`;

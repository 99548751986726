import { Time } from './built_in_msgs';
import { RosMessage } from './msg';

export class StringStamped extends RosMessage {
  static readonly type: string = 'application_msgs/StringStamped';

  stamp: Time = new Time();

  data: string = '';
}

export class Logging extends RosMessage {
  static readonly type: string = 'application_msgs/Logging';

  stamp: Time = new Time();

  name: string = '';

  description: string = '';
}
import ros from '../ros/ros-config';
import ROSLIB from 'roslib';
import { useEffect, useMemo, useState } from 'react';
import { RosMessage } from './msgs/msg';

interface RosState {
  isConnected: boolean;
  error?: any;
}

export function useRos() {
  const [rosState, setRosState] = useState<RosState>({
    isConnected: ros.isConnected,
    error: undefined,
  });

  ros.on('error', (error) => {
    setRosState({
      isConnected: ros.isConnected,
      error: error,
    });
  });

  ros.on('connection', () => {
    setRosState({
      isConnected: ros.isConnected,
      error: undefined,
    });
  });

  ros.on('close', () => {
    setRosState({
      isConnected: ros.isConnected,
      error: undefined,
    });
  });

  return rosState;
}

export function useSubscription<M extends RosMessage>(topic: string, type: string, callback: (message: M) => void) {
  const topicObj = useMemo(() => {
    const topicObj = new ROSLIB.Topic({
      ros: ros,
      name: topic,
      messageType: type,
    });

    topicObj.subscribe((message: ROSLIB.Message) => {
      callback(message as M);
    });

    return topicObj;
  }, []);

  return topicObj;
}

export const usePublisher = (topic: string, type: string) => {
  const [topicObj] = useState(
    new ROSLIB.Topic({
      ros: ros,
      name: topic,
      messageType: type,
    }),
  );

  return topicObj;
};

export const useService = (service: string, type: string) => {
  const [serviceObj] = useState(
    new ROSLIB.Service({
      ros: ros,
      name: service,
      serviceType: type,
    }),
  );

  return serviceObj;
};
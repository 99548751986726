import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
}

#root {
  width: 1290px;
  height: 690px;
  overflow-x: hidden;
  overflow-y: auto;
}

html{
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}`;
export interface LocationCommand{
    locationCode: string;
}

export interface PickingCommand extends LocationCommand{
    robotFloor: number;
    skuId: string;
    requestQuantity: 2
}

export enum FlodyStatus {
    IDLE = 'IDLE',
    CHARGING = 'CHARGING',
    START_PICKING = 'START_PICKING',
    START_UNLOADING = 'START_UNLOADING',
    START_LOADING = 'START_LOADING',
    START_CHARGING = 'START_CHARGING',
    MOVING_FOR_PICKING = 'MOVING_FOR_PICKING',
    MOVING_FOR_UNLOADING = 'MOVING_FOR_UNLOADING',
    MOVING_FOR_LOADING = 'MOVING_FOR_LOADING',
    MOVING_FOR_CHARGING = 'MOVING_FOR_CHARGING',
    PICKING = 'PICKING',
    UNLOADING = 'UNLOADING',
    UNLOADED = 'UNLOADED',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    EMERGENCY_STOPPED = 'EMERGENCY_STOPPED',
}
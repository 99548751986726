import { RosMessage } from './msg';
import { Header } from './std_msgs';

export class Point extends RosMessage {
  static readonly type: string = 'geometry_msgs/Point';

  x: number = 0.0;
  y: number = 0.0;
  z: number = 0.0;
}

export class Quaternion extends RosMessage {
  static readonly type: string = 'geometry_msgs/Quaternion';
  x: number = 0.0;
  y: number = 0.0;
  z: number = 0.0;
  w: number = 1.0;
}

export class Pose extends RosMessage {
  static readonly type: string = 'geometry_msgs/Pose';

  position: Point = new Point();

  orientation: Quaternion = new Quaternion();
}

export class PoseStamped extends RosMessage {
  static readonly type: string = 'geometry_msgs/PoseStamped';

  header: Header = new Header();

  pose: Pose = new Pose();
}

export class Vector3 extends RosMessage {
  static readonly type: string = 'geometry_msgs/Vector3';

  x: number = 0.0;
  y: number = 0.0;
  z: number = 0.0;
}

export class Twist extends RosMessage {
  static readonly type: string = 'geometry_msgs/Twist';

  linear: Vector3 = new Vector3();

  angular: Vector3 = new Vector3();
}


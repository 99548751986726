import React, { useEffect, useState, useRef } from "react";

import {
    useRos,
    usePublisher, 
    useSubscription,
    useService,
    Bool,
    Float32,
    Int32,
    StringStamped,
    Logging,
    Empty,
} from "../../../ros";

import { 
    DebugContainer,
    HeaderContainer,
    MainFlexContainer,
    FooterContainer,
    LeftContainer,
    CenterContainer,
    RightContainer,
    RobotViewerControlContainer,
    RobotStatusViewerContainer,
    HighLevelStatusContainer,
    DemoRobotMap,
    Connected,
    PauseButton,
    RestartButton,
    RobotBoolStatusViwer,
    RobotNumberStatusViwer,
    RobotStatusViewerName,
    RobotControlContainer,
    RobotControlButtonLeft,
    RobotControlButtonRight,
    PauseButtonContainer,
    RestartButtonContainer,
    MapContainer,
    LogBox,
    LogData,
} from "../../../components/styled/mobilehome";
import Map from '../../../assets/image/map.png'

const MobileHome = () => {
    const [batteryStatus, setBatteryStatus] = useState(0);

    const [pauseName, setPauseName] = useState("CONTINUE");

    const MAX_SIZE = 100;
    const [recievedDataArr, setRecievedDataArr] = useState<Array<Logging>>([]);
    const [reportStateDataArr, setReportStateDataArr] = useState<Array<StringStamped>>([]);

    const rosState = useRos();

    // subscriber monitoring data 
    // robot status
    const [bumperData, setBumperData] = useState(false);
    const [emergencyData, setEmergencyData] = useState(false);
    const [batteryData, setBatteryData] = useState(0.0);
    const [safetyLanternData, setSafetyLanternData] = useState(false);
    // pause status
    const [pauseData, setPauseData] = useState(false);

    // useSubscription
    useSubscription<Bool>('emergency', Bool.type, (msg) => {
        setEmergencyData(msg.data);
    });
    useSubscription<Bool>('uros/bumper', Bool.type, (msg) => {
        setBumperData(msg.data);
    });
    useSubscription<Float32>('battery/voltage', Float32.type, (msg) => {
        setBatteryData(msg.data);
        if(msg.data >= 27.5){
            setBatteryStatus(1);
        } else if(msg.data >= 26){
            setBatteryStatus(2);
        } else if (msg.data >= 24.5){
            setBatteryStatus(3);
        } else {
            setBatteryStatus(4);
        }
    });
    useSubscription<Bool>('uros/safety_lantern', Bool.type, (msg) => {
        setSafetyLanternData(msg.data);
    });
    // pause status
    useSubscription<Bool>('pause', Bool.type, (msg) => {
        setPauseData(msg.data);
        setPauseName(msg.data ? 'CONTINUE' : 'PAUSE');
    }); 
    // high-level
    useSubscription<Logging>('iot/recieved', Logging.type, (msg) => {
        setRecievedDataArr(arr => [msg, ...arr]);
    });
    useSubscription<StringStamped>('report_state', StringStamped.type, (msg) => {
        setReportStateDataArr(arr => [msg, ...arr]);
    });

    //publisher
    const msgSearchLight = usePublisher('search_light/light_cmd', Int32.type);
    const msgHeadLight = usePublisher('head_light/light_cmd', Int32.type);
    const msgSafetyLantern = usePublisher('uros/safety_lantern', Bool.type);

    // service
    const srvPause = useService('/pause', Empty.type);
    const srvUnPause = useService('/unpause', Empty.type);
    const srvRestart = useService('/iot/command/restart', Empty.type);

    const sendTopicSearchLight = (data: number) => {
        const SearchLightTopicMsg: Int32 = { data };
        msgSearchLight.publish(SearchLightTopicMsg);
    }
    const sendTopicHeadLight = (data: number) => {
        const HeadLightTopicMsg: Int32 = { data };
        msgHeadLight.publish(HeadLightTopicMsg);
    }
    const sendTopicSafetyLantern = (data: boolean) => {
        const SafetyLanternTopicMsg: Bool = { data };
        msgSafetyLantern.publish(SafetyLanternTopicMsg);
    }

    const pauseService = (isPaused: boolean) => {
        const request: Empty = {};
        if(isPaused){
            srvUnPause.callService(request, () => {});
        } else {
            srvPause.callService(request, () => {});
        }
    }   

    const restartService = () => {
        const request: Empty = {};
        srvRestart.callService(request, () => {});
    }

    return(
        <DebugContainer>
            <HeaderContainer>
                <Connected status={rosState.isConnected}>connect</Connected>
            </HeaderContainer>
            <MainFlexContainer>
                <LeftContainer>
                    <HighLevelStatusContainer>
                        <LogBox>{recievedDataArr.map((data, index) => {
                            return (<LogData key={index}>{JSON.parse(data.description).locationCode}</LogData>)
                        })}</LogBox>
                        <LogBox>{reportStateDataArr.map((data, index) => {
                            return (<LogData key={index}>{data.data}</LogData>)
                        })}</LogBox>
                    </HighLevelStatusContainer>
                </LeftContainer>
                <CenterContainer>
                    <RobotViewerControlContainer>
                        <RobotStatusViewerContainer>
                            <RobotStatusViewerName>emergency</RobotStatusViewerName>
                            <RobotBoolStatusViwer status={emergencyData}></RobotBoolStatusViwer>
                            <RobotStatusViewerName>bumper</RobotStatusViewerName>
                            <RobotBoolStatusViwer status={bumperData}></RobotBoolStatusViwer>
                            <RobotStatusViewerName>battery</RobotStatusViewerName>
                            <RobotNumberStatusViwer status={batteryStatus}>{batteryData.toFixed(1)}V</RobotNumberStatusViwer>
                        </RobotStatusViewerContainer>
                        <RobotControlContainer>
                            <RobotStatusViewerName>safety lantern</RobotStatusViewerName>
                            <RobotControlButtonLeft status={safetyLanternData} onClick={() => sendTopicSafetyLantern(false)}>OFF</RobotControlButtonLeft>
                            <RobotControlButtonRight status={safetyLanternData} onClick={() => sendTopicSafetyLantern(true)}>ON</RobotControlButtonRight>
                        </RobotControlContainer>
                    </RobotViewerControlContainer>
                </CenterContainer>
                <RightContainer>
                    <PauseButtonContainer>
                        <PauseButton status={pauseData} onClick={() => pauseService(pauseData)}>{pauseName}</PauseButton>
                    </PauseButtonContainer>
                    <RestartButtonContainer>
                        <RestartButton onClick={restartService}>이전 작업 재시작</RestartButton>
                    </RestartButtonContainer>
                </RightContainer>
            </MainFlexContainer>
            <FooterContainer>
                <MapContainer><DemoRobotMap src={Map} alt="Map.png"/></MapContainer>
            </FooterContainer>
        </DebugContainer>
    )
}

export default MobileHome
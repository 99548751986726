import styled from "styled-components";

import { colors } from "../../global/colors";

export const DebugContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: 10px
`;

export const HeaderContainer = styled.div`
    height: 99px;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const MainFlexContainer = styled.div`
    display: flex;
    hight: 100%;
`;

export const LeftContainer = styled.div`
    width: 30vw;
    hieght: 100%;
    margin: 20px;
`;

export const CenterContainer = styled.div`
    width: 55vw;
    hieght: 100%;
`;

export const RightContainer = styled.div`
    width: 15vw;
    hieght: 100%;
`;

export const RobotViewerControlContainer = styled.div`
    display: flex;
`

export const RobotStatusViewerContainer = styled.div`
    width: 180px;
    hieght: 210px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3,1fr);
    line-height: 70px;
    margin-left: 20px;
    margin-right: 20px;
`;

export const RobotControlContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 1fr 1fr;
    grid-template-rows: repeat(3,1fr);
    line-height: 70px;
    margin-left: 20px;
    margin-right: 20px;
`

export const HighLevelStatusContainer = styled.div`
    width: auto;
    display: flex;
`

interface RobotBoolStatus {
    status: boolean;
}

interface BatteryNumberStatus{
    status: number;
}

const batteryColorArr = ['#000000','#0040FF','#01DF3A','#FFBF00','#FE2E2E'];

// Header
export const Connected = styled.h2<RobotBoolStatus>`
    margin-left: 40px;
    color: ${(props) => (props.status ? colors.Connect : colors.NotConnect)};
`;

// Main
export const DemoRobotMap = styled.img`
    width: 100%;
`;

export const RobotBoolStatusViwer = styled.div<RobotBoolStatus>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => (props.status ? colors.On : colors.Off)};
    margin: auto;
    border: solid #000000 3px;
`;

export const RobotNumberStatusViwer = styled.p<BatteryNumberStatus>`
    color: ${(props) => (batteryColorArr[props.status])};
    font-size: 27px;
    font-weight: bold;
    text-align: center;
`;

export const RobotStatusViewerName = styled.p`
    width: 100%;
    font-size: 20px;
    text-align: left;
    margin: auto;
`;

export const RobotControlButtonLeft = styled.button<RobotBoolStatus>`
    width: 80px;
    height: 40px;
    border-radius: 0;;
    border: 0;
    margin: auto;
    background-color: ${(props) => (!props.status ? colors.ControlFalseOn : colors.ControlOff)};
`
export const RobotControlButtonRight = styled.button<RobotBoolStatus>`
    width: 80px;
    height: 40px;
    border-radius: 0;
    border: 0;
    margin: auto;
    background-color: ${(props) => (props.status ? colors.ControlTrueOn : colors.ControlOff)};
`

export const PauseButton = styled.button<RobotBoolStatus>`
    width: 150px;
    height: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0;
    background-color: ${(props) => (props.status ? colors.Off : colors.On)};
    cursor: pointer;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    float: right;
    margin-right: 15px;
    margin-bottom: 30px;
`;

export const LogBox = styled.div`
    width: 300px;
    height: 200px;
    background-color: #fff;
    border: 1px solid black;
    overflow-y: auto;
    float: left;
    margin: auto;
`;

export const LogData = styled.p`
    font-size: 22px;
`;

export const RestartButton = styled.button`
    width: 150px;
    height: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0;
    background-color: pink;
    cursor: pointer;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    float: right;
    margin-right: 15px;

    :active {
        position: relative;
        top: 3px;
    }
`;

export const OffButton = styled.button`
    width: 100px;
    height: 40px;
    border-radius: 10%;
`;
import styled from "styled-components";

import { colors } from "../../global/colors";

export const DebugContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: 10px
`;

export const HeaderContainer = styled.div`
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const MainFlexContainer = styled.div`
    height: 40vh;
    display: flex;
`;

export const FooterContainer = styled.div`
    height: 50vh;
`;

export const LeftContainer = styled.div`
    width: 30vw;
`;

export const CenterContainer = styled.div`
    width: 40vw;
`;

export const RightContainer = styled.div`
    width: 30vw;
`;

export const RobotViewerControlContainer = styled.div`
    height: 100%;
`;

export const RobotStatusViewerContainer = styled.div`
    height: 50%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3,1fr);
`;

export const RobotControlContainer = styled.div`
    height: 40%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3,1fr);
`;

export const HighLevelStatusContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2,1fr);
`;

export const PauseButtonContainer = styled.div`
    width: 70%;
    position: relative;
    
    :after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

export const RestartButtonContainer = styled.div`
    width: 70%;
    position: relative;
    margin-top: 2vh;

    :after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

export const MapContainer = styled.div`
    height: 50%;
    margin-top: 5px;
`;


interface RobotBoolStatus {
    status: boolean;
}

interface BatteryNumberStatus{
    status: number;
}

const batteryColorArr = ['#000000','#0040FF','#01DF3A','#FFBF00','#FE2E2E'];


// Header
export const Connected = styled.h2<RobotBoolStatus>`
    color: ${(props) => (props.status ? colors.Connect : colors.NotConnect)};
    font-size: 5vw;
`;

// Main
export const DemoRobotMap = styled.img`
    height: 100%;
`;

export const RobotBoolStatusViwer = styled.div<RobotBoolStatus>`
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background-color: ${(props) => (props.status ? colors.On : colors.Off)};
    margin: auto;
    border: solid #000000 0.5vw;
`;

export const RobotNumberStatusViwer = styled.p<BatteryNumberStatus>`
    color: ${(props) => (batteryColorArr[props.status])};
    text-align: center;
    font-weight: bold;
    font-size: 4vw;
`;

export const RobotStatusViewerName = styled.p`
    font-size: 3vw;
`;

export const RobotControlButtonLeft = styled.button<RobotBoolStatus>`
    width: 60%;
    height: 100%;
    border-radius: 0;
    border: 0;
    font-size: 3vw;
    background-color: ${(props) => (!props.status ? colors.ControlFalseOn : colors.ControlOff)};
`;
export const RobotControlButtonRight = styled.button<RobotBoolStatus>`
    width: 60%;
    height: 100%;
    border-radius: 0;
    border: 0;
    font-size: 3vw;
    background-color: ${(props) => (props.status ? colors.ControlTrueOn : colors.ControlOff)};
`;

export const PauseButton = styled.button<RobotBoolStatus>`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0;
    background-color: ${(props) => (props.status ? colors.Off : colors.On)};
    cursor: pointer;
    color: #000000;
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
`;

export const LogBox = styled.div`
    width: 90%;
    height: 90%;
    border: 1px solid black;
    overflow-y: auto;
    margin: auto;
`;

export const LogData = styled.p`
    font-size: 2vw;
`;

export const RestartButton = styled.button`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0;
    background-color: pink;
    cursor: pointer;
    color: #000000;
    font-size: 2.5vw;
    font-weight: bold;
    text-align: center;
`;
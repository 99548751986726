import ROSLIB from 'roslib';

const ros = new ROSLIB.Ros({});
console.log(process.env.REACT_APP_ROS_WS_PORT!!);


ros.on('connection', () => {
    console.log('web socket connection successed!!');
});

// ros.on('close', () => {
//     setTimeout(() => {
//         ros.connect(process.env.REACT_APP_ROS_WS_PORT!!);
//     }, 1000);
// });

ros.connect(process.env.REACT_APP_ROS_WS_PORT!!);

export default ros;
